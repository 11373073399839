<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE LOS PUESTOS SENSIBLES ANTISOBORNO
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-users-cog fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaEvaluaciones.filter(x => x.tipo>6).length}}</span>
                            <br>
                            <span class="text-muted">Puestos sensibles antisoborno</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Puestos sensibles antisoborno</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaEvaluaciones.filter(x => x.tipo>6)" :fields="campoEvaluaciones" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                
                                <template #cell(tipo)="param">
                                    <span v-if="param.item.tipo > 6 && param.item.tipo < 16"><i class="fas fa-circle text-warning"></i> Medio</span>
                                    <span v-else-if="param.item.tipo >= 16"><i class="fas fa-circle text-danger"></i> Alto</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        
    },
    data() {
        return {

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoEvaluaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Riesgo",
                    class: "text-center",
                },
                {
                    key: "puestoTrabajo",
                    label: "Puesto de trabajo",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },
            ],
            listaEvaluaciones: [],
            listaEvaluaciones: [],
            datosSession: {
                idCliente: ''
            },
            datosEstadistica: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarPuestosSensibles() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-evaluaciones-riesgos-antisoborno-sga", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {

                    const setObj = new Set(); // creamos pares de clave y array
                    const unicos = response.data.reduce((acc, item) => {
                        if (!setObj.has(item.idRiesgo)) {
                            setObj.add(item.idRiesgo, item)
                            acc.push(item)
                        }
                        return acc;
                    }, []);
                    //me.listaEvaluaciones = unicos;
                    for (const i in unicos) {
                        if(unicos[i].datosAcordionRiesgo){
                            me.listaEvaluaciones.push({
                                nombre: unicos[i].nombre,
                                puestoTrabajo: unicos[i].puestoTrabajo,
                                tipo: JSON.parse(unicos[i].datosAcordionRiesgo).tipoActual,
                            })
                        }else{
                            me.listaEvaluaciones.push({
                                nombre: unicos[i].nombre,
                                puestoTrabajo: unicos[i].puestoTrabajo,
                                tipo: unicos[i].tipo,
                            })
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPuestosSensibles();
        }
    }

}
</script>
